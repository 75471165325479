import React, { Fragment, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const insertLink = (path, name) => {
  return (
    <Link
      key={path}
      to={path || '/'}
      partiallyActive={true}
      activeClassName={'active'}
      className='section-toc__item-link'
    >
      {name}
    </Link>
  )
}

const generateLayout = pages => {
  return pages.map(page => {
    const { path, name } = page
    return (
      <li key={name} className='section-toc__item'>
        {page?.pages?.length > 0 ? (
          <Fragment>
            <div className='section-toc__item-collapsible'>
              {insertLink(path, name)}
              <button>
                <span className='css-chevron' />
              </button>
            </div>
            <ul className='content-group'>{generateLayout(page.pages)}</ul>
          </Fragment>
        ) : (
          insertLink(path, name)
        )}
      </li>
    )
  })
}

const handleClick = ({ target: { parentNode } }) => {
  /* Open Target */
  parentNode.toggleAttribute('aria-expanded')
}

export default function Sidebar({ context }) {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allToC {
        edges {
          node {
            id
            name
            context
            pages {
              name
              path
              pages {
                name
                path
                pages {
                  name
                  path
                }
              }
            }
          }
        }
      }
    }
  `)

  const [sidebarItems] = data.allToC.edges.filter(({ node }) => {
    const location = node.context || '/'
    return location === context?.section
  })

  useEffect(() => {
    /* Open Route Specific Sidebar */
    document.querySelectorAll('.sidebar .active').forEach(element => {
      element.parentNode.setAttribute('aria-expanded', true)
    })
  }, [])

  return (
    <div role='navigation' className='sidebar' onClick={handleClick}>
      <ul className='section-toc'>
        {generateLayout(sidebarItems?.node.pages)}
      </ul>
    </div>
  )
}
