import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import './overview.scss'

const Overview = ({ pages, frontmatter, section, fileDirectory }) => {
  const data = useStaticQuery(graphql`
    query pageQuery {
      allToC {
        edges {
          node {
            name
            context
            pages {
              name
              path
              pages {
                name
                path
                pages {
                  name
                  path
                }
              }
            }
          }
        }
      }
    }
  `)

  const pathLevels = fileDirectory.split('/').slice(1)

  const getSubpages = (pages, count = 0) => {
    count++
    const subpage = pages.find(page => {
      return page.path.includes(pathLevels[count])
    })

    if (count < pathLevels.length - 1) {
      return getSubpages(subpage.pages, count)
    } else {
      return subpage?.pages
    }
  }

  const contextToC = data.allToC.edges.find(({ node }) => {
    return node.context === section
  })

  const getSectionContentList = () => {
    if (pathLevels.length === 1) {
      return contextToC.node.pages.filter(page => {
        return page.path !== fileDirectory
      })
    } else if (pathLevels.length >= 2) {
      return getSubpages(contextToC.node.pages)
    } else {
      return []
    }
  }

  const contentList = getSectionContentList()

  return (
    <ul className='overview-container'>
      {contentList.length &&
        contentList.map((contentPage, idx) => {
          const { name } = contentPage

          const {
            path,
            pageContext: { previewText }
          } = pages.find(page => {
            return page.path === contentPage.path
          })

          return (
            <li key={`cl${idx}`}>
              <Link to={path}>
                {name && <h3>{name}</h3>}
                {previewText && <p>{previewText}</p>}
              </Link>
            </li>
          )
        })}
    </ul>
  )
}

export default Overview
