import React, { Fragment, useContext } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Page from '../components/page'
import Sidebar from '../components/sidebar'
import AuthContext from '../context/AuthContext'
import RestrictedContent from '../components/restricted'
import { hideSection } from '../utils'

export const query = graphql`
  query ($pageId: String!, $fileDirectory: String) {
    markdownRemark(id: { eq: $pageId }) {
      id
      html
      frontmatter {
        title
        showSectionTeasers
      }
      tableOfContents
    }
    allSitePage(filter: {path: {glob: $fileDirectory}}) {
      nodes {
        id
        path
        pageContext
      }
    }
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const { user, isFetching } = useContext(AuthContext)
  const { markdownRemark, allSitePage } = data 

  const tableOfContents = markdownRemark?.tableOfContents

  return (
    <Layout context={pageContext} >
      {!isFetching && (
        <Fragment>
          {hideSection(pageContext.section, user?.permissions)
            ? <RestrictedContent />
            : <Fragment>
                <Sidebar context={pageContext} />
                <Page
                  frontmatter={markdownRemark?.frontmatter}
                  html={markdownRemark?.html}
                  context={pageContext}
                  pages={allSitePage?.nodes}
                />
                <div
                  className='page-toc'
                  dangerouslySetInnerHTML={{ __html: tableOfContents}}
                />
              </Fragment>
          }
        </Fragment>
      )}
    </Layout>
  )
}

export default PageTemplate