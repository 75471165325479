import React from 'react'
import Overview from './overview'

const Page = ({ frontmatter, html, context, pages }) => (
  <div className='page-content'>
    <h1>
      {frontmatter.title} 
    </h1>
    <div
      dangerouslySetInnerHTML={{ __html: html }}
    />
    {frontmatter.showSectionTeasers && (
      <Overview
        pages={pages}
        frontmatter={frontmatter}
        section={context.section}
        fileDirectory={context.fileDirectory.replace('/*', '')}
      />
    )}
  </div>
)

export default Page