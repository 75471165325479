import React, { useEffect, useContext } from 'react'
import Navigation from './navigation'
import AuthContext from '../context/AuthContext'
import { Link } from 'gatsby'
import './layout.scss'

const RestrictedContent = () => {

  return (
    <div className='restricted-content-container'>
        <div>
          <p>!</p>
        </div>
        <div>
          <h1>You've found the restricted section</h1>
          <p>You need to <Link to='/sign-in'>sign in</Link> to view this content.</p>
          <p>If you're already signed in, talk to your platform administrator about updating your permissions.</p>
        </div>

    </div>
)}

export default RestrictedContent